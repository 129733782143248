import React from 'react';
import LayoutOne from '../images/layout-1.svg';
import LayoutTwo from '../images/layout-2.svg';
import LayoutThree from '../images/layout-3.svg';
import EventIcon from '../images/event-icon.svg';
import Line from '../images/line.svg';
import HorizontalLine from '../images/horizontal-line.svg';
import Whale from '../images/whale.jpg';
import Rainforest from '../images/rainforest.jpg';
import ArrowAug from '../images/arrow-aug.svg';
import ArrowSept from '../images/arrow-sept.svg';

const Demo = ({ matches }) => {
  return (
    <div className='demo'>



      <div className='demo__example container'>
        <img
          src={ArrowAug}
          alt='Arrow pointing to card to tell viewer that it will be displayed for the month of August'
          className='demo__example--arrow-aug'
        />
        <img
          src={ArrowSept}
          alt='Arrow pointing to card to tell viewer that it will be displayed for the month of September'
          className='demo__example--arrow-sept'
        />
        {matches ? (
          <>
            <div className='demo__example--card'>
              <div className='demo__example--card--left-col'>
                <img
                  src={Whale}
                  alt='A whale breaching the ocean'
                  className='demo__example--card--image'
                />
              </div>
              <div className='demo__example--card--right-col'>
                <h4 className='demo__example--card--title'>Save the Ocean</h4>

                <p className='demo__example--card--text'>
                  Our oceans needs us now more than ever. Duis sodales dignissim
                  fames habitant. Donec duis velit consequat, amet, pulvinar
                  turpis nam. Aliquet consequat massa urna euismod bibendum sed.
                  Auctor sem nibh purus facilisi aenean enim nibh duis orci.
                </p>
                <button className='demo__example--card--button'>Donate</button>
              </div>
            </div>
            <div className='demo__example--card'>
              <div className='demo__example--card--left-col'>
                <img
                  src={Rainforest}
                  alt='Overlooking the rainforest'
                  className='demo__example--card--image'
                />
              </div>
              <div className='demo__example--card--right-col'>
                <h4 className='demo__example--card--title'>
                  Save the Rainforest
                </h4>

                <p className='demo__example--card--text'>
                  Our rainforests needs us now more than ever. Duis sodales
                  dignissim fames habitant. Donec duis velit consequat, amet,
                  pulvinar turpis nam. Aliquet consequat massa urna euismod
                  bibendum sed. Auctor sem nibh purus facilisi aenean enim nibh
                  duis orci.
                </p>
                <button className='demo__example--card--button'>Donate</button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='demo__example--card'>
              <h4 className='demo__example--card--title'>Save the Ocean</h4>
              <img
                src={Whale}
                alt='A whale breaching the ocean'
                className='demo__example--card--image'
              />
              <p className='demo__example--card--text'>
                Our oceans needs us now more than ever. Duis sodales dignissim
                fames habitant. Donec duis velit consequat, amet, pulvinar
                turpis nam. Aliquet consequat massa urna euismod bibendum sed.
                Auctor sem nibh purus facilisi aenean enim nibh duis orci.
              </p>
              <button className='demo__example--card--button'>Donate</button>
            </div>
            <div className='demo__example--card'>
              <h4 className='demo__example--card--title'>
                Save the Rainforest
              </h4>
              <img
                src={Rainforest}
                alt='overlooking the rainforest'
                className='demo__example--card--image'
              />
              <p className='demo__example--card--text'>
                Our rainforests needs us now more than ever. Duis sodales
                dignissim fames habitant. Donec duis velit consequat, amet,
                pulvinar turpis nam. Aliquet consequat massa urna euismod
                bibendum sed. Auctor sem nibh purus facilisi aenean enim nibh
                duis orci.
              </p>
              <button className='demo__example--card--button'>Donate</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Demo;
