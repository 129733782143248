import React, {useEffect, useLayoutEffect, useState} from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import One from '../images/one.jpg'
import Two from '../images/two.jpg'
import Three from '../images/three.jpg'
import Four from '../images/four.jpg'
import Five from '../images/five.jpg'
import Six from '../images/six.jpg'
import Seven from '../images/seven.jpg'
import Eight from '../images/eight.jpg'

// const convertArrayTo3d = (list: any[]): any[][] => {
//     let tmpList: any[] = [];
//     const newList: any[] = [];
//     for (let i = 0; i < list.length + 1; i++) {
//         if (tmpList.length < 3 ) {
//             tmpList.push(list[i]);
//         } else {
//             newList.push(tmpList);
//             tmpList = [list[i]];
//             if (i === list.length - 1) {
//                 console.log('finalizing: ', tmpList)
//                 newList.push(tmpList);
//             }
//         }
//     }
//
//     return newList;
// };

const Carousel = () => {
    // const [index, setIndex] = useState<number>(0)
    const [imageList, setImageList] = useState([ Two, Three, Four, Five, Six, Seven, Eight, One])

    // const [multiImageList, setMultiImageList] = useState<React.ReactNode[][]>([])
    //
    // useLayoutEffect(() => {
    //     setMultiImageList(convertArrayTo3d(imageList))
    //     console.log('param: ', imageList)
    //     console.log('3rd: ', convertArrayTo3d(imageList))
    // }, [imageList])
    //
    // useEffect(() => {
    //     console.log('multiImageList', multiImageList)
    // }, [multiImageList])
    //
    // useLayoutEffect(() => {
    //     if(index === multiImageList.length ) {
    //         setIndex(0)
    //     }
    //
    //     setTimeout(() => {
    //         setIndex(index + 1)
    //     }, 4000)
    //
    //
    // }, [index, multiImageList]);






    return(
        <ReactCarousel autoPlay={true} infiniteLoop={true} transitionTime={2000} showArrows={false} interval={4000} showStatus={false}>
            {imageList.map((item, i )=> <ImageWrapper><Image key={uuid()} src={item} alt={'template'} /></ImageWrapper>)}
        </ReactCarousel>
    )
}

export default Carousel

const Container = styled.div<{index: number}>`
  display: flex;
  transform: translateX(${props => props.index * -100}%);
  transition: transform 2000ms ease-in-out;
  width: 100vw;
`

const Image = styled.img`
  width: 100%;
 margin-right: 2rem;
`

const ImageWrapper = styled.div`
 
`

const Wrapper = styled.div`
  overflow: hidden;
`