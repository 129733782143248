import React from 'react';
import styled from 'styled-components'
import Carousel from "../components/Carousel";

const Templates = () => {
    return (
        <Container >
            <InnerContainer>
                <Title>Professional Templates</Title>
                <Text>
                    Responsive templates and a full-featured design studio gives you eye-catching results that will get the most engagement from your audience.
                </Text>
                <Carousel />
            </InnerContainer>

        </Container>
    );
}

export default Templates;

const Container = styled.div`
  padding: 5rem 2rem;
  display: flex;
 
  justify-content: center;

  background-color: ${props => props.theme.color.white};

  @media screen and (min-width: 768px) {
   padding: 5rem;
  }
`

const Text = styled.div`
  color: ${props => props.theme.color.blue900};
  padding-bottom: 5rem;
  max-width: 70rem;
  text-align: left;
`

const Title = styled.h3`
  color: ${props => props.theme.color.blue900};
  padding-bottom: 2rem;
  font-size: 3.2rem;
  text-align: center;
  
  @media screen and (min-width: 768px) {
    font-size: 4.8rem;
  }
`

const InnerContainer = styled.div`
    max-width: 114rem;
    min-width: 32rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
    `