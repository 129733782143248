import * as React from 'react';
import Nav from '../components/Nav';
import Hero from '../sections/Hero';
import Products from '../sections/Products';
import Cards from '../sections/Cards';
import About from '../sections/About';
import Footer from '../sections/Footer';
import Support from '../sections/Support';
import Demo from '../sections/Demo';
import '../styles/main.scss';
import useMediaQuery from "../hooks/useMediaQuery";
import { AppProvider } from '../contexts/AppContext';
import { theme } from "../styles/theme";
import { ThemeProvider } from "styled-components";
import Templates from "../sections/Templates";

const Index = () => {
  const matches = useMediaQuery('(min-width:1140px)');


  return (
    <AppProvider>
      <ThemeProvider theme={theme}>
      <div>
        <div className='bg-container'>
          <Nav matches={matches} />
          <main>
            <Hero matches={matches} />
            <Products matches={matches} />
            <Cards matches={matches} />
              <Templates />
            <Demo matches={matches} />
            <About matches={matches} />
            <Support />
          </main>
          <Footer />
        </div>
      </div>
      </ThemeProvider>
    </AppProvider>
  );
};

export default Index;


